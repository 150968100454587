window._ = require('lodash');

try {
    window.$ = window.jQuery = require('jquery');
    window.moment = require('moment');
    require('bootstrap');
    require('datatables.net-bs4');
    require('datatables.net-responsive-bs4');
    require('datatables.net-buttons-bs4');
    require('datatables.net-buttons/js/buttons.html5.js');
    require('jquery-mask-plugin');
    configuringDataTable();
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

$(document).ready(function() {
    $('#modalEnable').on('show.bs.modal', function(event) {
        var button = $(event.relatedTarget);
        var cat_id = button.data('catid');
        var cat_name = button.data('cattext');
        var modal = $(this);

        modal.find('#modalForm').attr('action', cat_id);
        modal.find('.modal-body #Modal-text').html(cat_name);
    });

    $('#modalImportar').on('show.bs.modal', function(event) {
        var button = $(event.relatedTarget);
        var cat_id = button.data('catid');
        var cat_name = button.data('cattext');
        var modal = $(this);

        modal.find('#modalForm').attr('action', cat_id);
        modal.find('#Modal-text').html(cat_name);
    });

    $('#modalEnableLinkPagamento').on('show.bs.modal', function(event) {
        var button = $(event.relatedTarget);
        var parcela_id = button.data('catid');
        var link_pagamento = button.data('cattext');
        var modal = $(this);

        modal.find('#parcela_id').val(parcela_id);
        modal.find('#link_pagamento').val(link_pagamento);
        $('.access-link').on('click', function() {
            let link_pagamento = $('#link_pagamento').val();
            if(link_pagamento.search('https://') === -1 || link_pagamento.search('http://') === -1) {
                window.open('https://'+link_pagamento, '_blank');
            }
        });
        $('.copy-link').on('click', function() {
            $('#link_pagamento').select();
            document.execCommand('copy');
        });
    });
    
    $('.datatable').DataTable({
        responsive: true
    });

    $('.date').mask('00/00/0000');
    $('.time').mask('00:00:00');
    $('.date_time').mask('00/00/0000 00:00');
    $('.cep').mask('00000-000');
    $('.phone').mask('0000-0000');
    $('.ano').mask('0000');
    $('.phone_with_ddd').mask('(00) 0000-0000');
    $('.phone_us').mask('(000) 000-0000');
    $('.mixed').mask('AAA 000-S0S');
    $('.cpf').mask('000.000.000-00', { reverse: true });
    $('.cnpj').mask('00.000.000/0000-00', { reverse: true });
    $('.money').mask('000.000.000.000.000,00', { reverse: true });
    $('.money2').mask('#.##0,00', { reverse: true });
    $('.rg').mask('#', { reverse: true });
    $('.fator_cm').mask('0,0000000');
    $('.placa').mask('AAAAAAA');
    $('.renavam').mask('00000000000');
    $('.numero').mask('############');

    var SPMaskBehavior = function(val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    spOptions = {
        onKeyPress: function(val, e, field, options) {
            field.mask(SPMaskBehavior.apply({}, arguments), options);
        }
    };

    $('.sp_celphones').mask(SPMaskBehavior, spOptions);

    var CpfCnpjMaskBehavior = function(val) {
            return val.replace(/\D/g, '').length <= 11 ? '000.000.000-009' : '00.000.000/0000-00';
        },
        cpfCnpjpOptions = {
            onKeyPress: function(val, e, field, options) {
                field.mask(CpfCnpjMaskBehavior.apply({}, arguments), options);
            }
        };

    $('.cpf_cnpj').mask(CpfCnpjMaskBehavior, cpfCnpjpOptions);

    $('.percent').mask('P', {
        translation: {
            'P': {
                pattern: /[\d\.,]/,
                recursive: true
            }
        },
        onKeyPress: function(val, e, field, options) {
            var old_value = $(field).data('oldValue') || '';
    
            val = val.trim();
            val = val.replace(',', '.');
            val = val.length > 0 ? val : '0';
    
            // Transformando múltiplos pontos em um único ponto
            val = val.replace(/[\.]+/, '.');
    
            // Verificando se o valor contém mais de uma ocorrência de ponto
            var dot_occurrences = (val.match(/\./g) || []).length > 1;
    
            // Verificando se o valor está de acordo com a sintaxe do float
            var is_float = /[-+]?[\d]*\.?[\d]+/.test(val);
    
            if (dot_occurrences || !is_float) {
                val = old_value;
            }
    
            // Força o valor a ficar no intervalo de 0 à 100
            val = parseFloat(val) >= 100 ? '100' : val;
            val = parseFloat(val) <  0   ? '0'   : val;

            if(val.indexOf('.') > -1){
                aux = val.substring(val.indexOf('.') + 1,val.length);
                if(aux.length >= 3){
                    val = val.substring(0,val.length - 1);
                }
            }
    
            $(field)
                .val(val)
                .data('oldValue', val);
        }
    });


    function limpa_formulário_cep() {
        // Limpa valores do formulário de cep.
        $("#rua").val("");
        $("#bairro").val("");
        $("#cidade").val("");
        $("#uf").val("");
    }
    
    //Quando o campo cep perde o foco.
    $("#cep").blur(function() {

        //Nova variável "cep" somente com dígitos.
        var cep = $(this).val().replace(/\D/g, '');

        //Verifica se campo cep possui valor informado.
        if (cep != "") {

            //Expressão regular para validar o CEP.
            var validacep = /^[0-9]{8}$/;

            //Valida o formato do CEP.
            if(validacep.test(cep)) {

                //Preenche os campos com "..." enquanto consulta webservice.
                $("#rua").val("...");
                $("#bairro").val("...");
                $("#cidade").val("...");
                $("#uf").val("...");

                //Consulta o webservice viacep.com.br/
                $.getJSON("https://viacep.com.br/ws/"+ cep +"/json/?callback=?", function(dados) {

                    if (!("erro" in dados)) {
                        //Atualiza os campos com os valores da consulta.
                        $("#rua").val(dados.logradouro);
                        $("#bairro").val(dados.bairro);
                        $("#cidade").val(dados.localidade);
                        $("#uf").val(dados.uf);
                    } //end if.
                    else {
                        //CEP pesquisado não foi encontrado.
                        limpa_formulário_cep();
                        alert("CEP não encontrado.");
                    }
                });
            } //end if.
            else {
                //cep é inválido.
                limpa_formulário_cep();
                alert("Formato de CEP inválido.");
            }
        } //end if.
        else {
            //cep sem valor, limpa formulário.
            limpa_formulário_cep();
        }
    });

});

function configuringDataTable() {
    const language = require('datatables.net-plugins/i18n/pt_br.json');

    $.extend($.fn.dataTable.defaults, {
        language: language,
        responsive: true
    });
}